<template lang="pug">
.FcwShowcaseHomeTemplate
  FcwHero(v-bind="hero")

  #blocks-banner
  FcwSection.FcwShowcaseHomeTemplate__highlightGridSection(
    corners="sharp"
    no-padding
  )
    FcwHighlightGrid(:items="highlightGridSection")
  FcwSection(v-if="imageWithTextListSection.length > 0")
    FcwSectionContent
      FGrid(
        columns="1"
        gap="48"
      )
        FGridItem(
          v-for="(imageWithTextItem, index) in imageWithTextListSection"
          :key="index"
          span="1"
        )
          FcwImageTextCta(
            v-bind="imageWithTextItem"
            :reverse="index % 2 !== 0"
          )
  FcwSection.FcwShowcaseHomeTemplate__overlappingSection(
    v-if="clientState.pages.showcaseBlog && blogPostsSection.blogPosts.length > 0"
    color="neutral--light-4"
  )
    FcwSectionContent
      FcwBlogPostsCarousel(
        :title="blogPostsSection.title"
        :items="blogPostsSection.blogPosts"
        :cta-link="{ name: 'showcase-blog' }"
      )

  FcwSection.FcwShowcaseHomeTemplate__imagesMosaicSection.FcwShowcaseHomeTemplate__overlappingSection(
    v-if="imagesMosaicSection.mosaic.length > 0"
    color="primary"
  )
    FcwSectionContent
      h3 {{ imagesMosaicSection.title }}
      FcwImagesMosaic(:images="imagesMosaicSection.mosaic")
</template>

<style lang="stylus">
.FcwShowcaseHomeTemplate
  h3
    margin-bottom rem(32)

  .FcwSection
    margin-top rem(72)

    &:last-child
      margin-bottom rem(120)

      +media-down('sm')
        margin-bottom rem(80)

  .FcwShowcaseHomeTemplate__overlappingSection,
  .FcwShowcaseHomeTemplate__overlappingSection:last-child
    margin-bottom rem(-24)

  .FcwShowcaseHomeTemplate__overlappingSection + .FcwShowcaseHomeTemplate__overlappingSection
    margin-top rem(-24)

  .FcwShowcaseHomeTemplate__highlightGridSection
    margin-top 0

.FcwShowcaseHomeTemplate__imagesMosaicSection
  color var(--color--neutral--light-5)

#blocks-banner
  scroll-margin-top rem(72)
</style>

<script lang="ts" setup>
import type { FcwBlogPostCardProps } from '@/components/organisms/FcwBlogPostCard.vue';
import type { FcwHighlightGridProps } from '@/components/organisms/FcwHighlightGrid.vue';
import type { FcwHeroProps } from '@/components/organisms/FcwHero.vue';
import type { FcwImageTextCtaProps } from '@/components/molecules/FcwImageTextCta.vue';
import type { ImageWithLink } from '@/components/organisms/FcwImagesMosaic.vue';

export interface FcwShowcaseHomeTemplateProps {
  /**
   * Page hero
   */
  hero: FcwHeroProps;
  /**
   * Highlight grid section items
   */
  highlightGridSection: FcwHighlightGridProps['items'];
  /**
   * Images with text list section
   */
  imageWithTextListSection: FcwImageTextCtaProps[];
  /**
   * Featured blog posts section
   */
  blogPostsSection: { title: string; blogPosts: FcwBlogPostCardProps[] };
  /**
   * Images mosaic section
   */
  imagesMosaicSection: { title: string; mosaic: ImageWithLink[] };
}

defineProps<FcwShowcaseHomeTemplateProps>();

const { clientState } = useClientStore();
</script>
