<template lang="pug">
FcwShowcaseHomeTemplate(v-bind="showcaseHomeData")
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'showcase',
});

const { data: showcaseHomeData, seoData, error } = await useCmsShowcaseHome();
useCmsError(error);

useClientSeoMeta(seoData);
</script>
