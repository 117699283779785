import { mapCmsShowcaseDataToShowcaseHomeProps } from '@/lib/cms-mappers/showcase/home.mappers';
import {
  mapCmsHeroDataToSeoMeta,
  mapCmsSeoDataToSeoMeta,
} from '@/lib/cms-mappers/common.mappers';

import type { FcwShowcaseHomeTemplateProps } from '@/components/templates/FcwShowcaseHomeTemplate.vue';

const showcasePopulateFields = [
  'hero.image',
  'cta_blocks_banner_section.image',
  'image_with_text_list_section.image',
  'mosaic_section.mosaic.image',
  'featured_blog_posts_section.blog_posts.hero_image',
  'seo.image',
];

/**
 * Returns showcase home data fetched from CMS (Strapi)
 */
export async function useCmsShowcaseHome(): UseCmsResponse<
  FcwShowcaseHomeTemplateProps,
  'seoData'
> {
  const { data, error, pending, refresh } = await useStrapi('showcase-home', {
    queryParams: {
      populate: showcasePopulateFields.join(','),
    },
  });

  const mappedData = computed(() =>
    mapCmsShowcaseDataToShowcaseHomeProps(data.value?.data)
  );

  const seoData = computed(() =>
    data.value?.data?.attributes?.seo
      ? mapCmsSeoDataToSeoMeta(data.value?.data?.attributes?.seo)
      : mapCmsHeroDataToSeoMeta(data.value?.data?.attributes?.hero)
  );

  return { data: mappedData, seoData, error, pending, refresh };
}
