import {
  mapCmsImageToHeroImagesProps,
  mapCmsImageToImageProps,
} from '@/lib/cms-mappers/common.mappers';

import type { FcwBlogPostCardProps } from '@/components/organisms/FcwBlogPostCard.vue';
import type { FcwShowcaseHomeTemplateProps } from '@/components/templates/FcwShowcaseHomeTemplate.vue';

export function mapCmsShowcaseDataToShowcaseHomeProps(
  showcaseHomeRawData: StrapiResponseData<ShowcaseHomeContentType> | undefined
): FcwShowcaseHomeTemplateProps {
  const cmsData = showcaseHomeRawData?.attributes;

  const hero: FcwShowcaseHomeTemplateProps['hero'] = {
    images: mapCmsImageToHeroImagesProps(cmsData?.hero?.image),
    title: cmsData?.hero?.title ?? '',
    subtitle: cmsData?.hero?.subtitle ?? '',
    ctaLocation: '#blocks-banner',
  };

  const highlightGridSection: FcwShowcaseHomeTemplateProps['highlightGridSection'] =
    (cmsData?.cta_blocks_banner_section ?? []).map(item => ({
      title: item.title ?? '',
      description: item.text ?? '',
      buttonText: item.button_text,
      buttonLink: item.button_link,
      image: mapCmsImageToImageProps(item.image),
    }));

  const imageWithTextListSection: FcwShowcaseHomeTemplateProps['imageWithTextListSection'] =
    (cmsData?.image_with_text_list_section ?? []).map(item => ({
      title: item.title ?? '',
      text: item.text ?? '',
      buttonText: item?.cta_text ?? '',
      buttonLocation: item?.cta_link ?? '',
      image: mapCmsImageToImageProps(item.image, 'medium'),
    }));

  const imagesMosaicSection: FcwShowcaseHomeTemplateProps['imagesMosaicSection'] =
    {
      title: cmsData?.mosaic_section?.title ?? '',
      mosaic: (cmsData?.mosaic_section?.mosaic ?? []).map(item => ({
        link: item.link,
        ...mapCmsImageToImageProps(item.image, 'medium'),
      })),
    };

  const blogPostsSection: FcwShowcaseHomeTemplateProps['blogPostsSection'] = {
    title: cmsData?.featured_blog_posts_section?.title ?? '',
    blogPosts: (
      cmsData?.featured_blog_posts_section?.blog_posts?.data ?? []
    ).map((blogPost: { attributes: BlogPostContentType }) => {
      const blogPostData = blogPost?.attributes;
      const data: FcwBlogPostCardProps = {
        title: blogPostData.title ?? '',
        slug: blogPostData?.slug ?? '',
        date: blogPostData?.publishedAt,
        image: mapCmsImageToImageProps(blogPostData?.hero_image, 'medium'),
        background:
          blogPostData.background_color && blogPostData.second_background_color
            ? [
                blogPostData.background_color,
                blogPostData.second_background_color,
              ]
            : blogPostData.background_color ?? undefined,
      };
      return data;
    }),
  };

  return {
    hero,
    highlightGridSection,
    imageWithTextListSection,
    blogPostsSection,
    imagesMosaicSection,
  };
}
